<template>
  <BaseDialog
    :propDialog="dialog"
    :max-width="400"
    title="order.qr-scan"
    :actionable="false"
    @closeDialog="setDialog"
  >
    <QrcodeStream
      v-if="!qrError"
      :camera="mode"
      @init="onInit"
      @decode="onDecode"
      @error="onError"
    ></QrcodeStream>
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      loader="bars"
    ></Loading>
    <div
      v-if="qrError"
      class="d-flex flex-column justify-center font-weight-medium text-center mx-6"
    >
      <div
        class="align-self-center deep-purple--text text-body-2 font-italic mb-2"
      >
        {{ $t('order.qr-no-scan') }}
      </div>
      <BaseIconButton
        class="align-self-center"
        icon-name="refresh"
        @action="reload"
      />
    </div>
  </BaseDialog>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { displayErrorMessage } from '@/utils'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'QrScan',

  components: {
    Loading,
    QrcodeStream
  },

  props: {
    dialog: Boolean
  },

  data: () => ({ isLoading: false, qrError: false, mode: 'rear' }),

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    async onInit(promise) {
      this.isLoading = true
      try {
        await promise
        // successfully initialized
      } catch (error) {
        this.qrError = true
        if (process.env.NODE_ENV === 'production') {
          displayErrorMessage(this.$t('error.qrFailed'))
        } else {
          displayErrorMessage(error.message)
        }
      } finally {
        this.isLoading = false
      }
    },

    onDecode(result) {
      const decoded = result.split('.')
      this.$socket.client.emit(decoded[0], decoded[1])
    },

    onError(error) {
      const triedRearCamera = this.mode === 'rear'
      const cameraMissingError = error.name === 'OverconstrainedError'
      if (triedRearCamera && cameraMissingError) {
        this.mode = 'front'

      }
    },

    reload() {
      this.$loading.show()
      this.$auth.loginWithRedirect()
    }
  }
}
</script>

<style></style>
